import React from 'react';

import Meta from '../components/meta';

import ExtLink from '../components/extLink';

import Contents from '../components/contents';

import SubPageNav from '../components/subPageNav';

const History = () =>{

    return(
        <>

            <Meta
                title="沿革"
                description="株式会社アクロスソリューションズの沿革をご案内します。"
            />

            <Contents>

                <div className="c-sub-header">
                    <SubPageNav static="history" />

                    <em className="c-sub-header--eng u-josef">
                    HISTORY
                    </em>

                    <h1 className="c-sub-header--title">
                    沿革
                    </h1>
                </div>

                <div className="p-outline-wrap">

                    <div className="p-outline is-disp">

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2006年<span className="is-date">5月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                株式会社アクロス設立
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2007年<span className="is-date">4月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                欧米向けクラフトショッピングモール開設をプロデュース
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2007年<span className="is-date">6月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                エクスポート・ジャパン株式会社と業務提携を締結
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2008年<span className="is-date">2月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                本社を金沢市千日町4-3に移転
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2009年<span className="is-date">9月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                ExseedJapan.comを石川県版に特化
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2009年<span className="is-date">12月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                国内初となる行動ターゲティングメール配信システム(アトム)をリリース
                                </p>
                                <p className="p-outline-box-cnt--text">
                                丸紅情報システムズ株式会社と業務協力を開始
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2010年<span className="is-date">1月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                国内初となる行動履歴マーケティングシステム(アトムス)をリリース
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2010年<span className="is-date">3月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                本社を金沢市西念1丁目2-26に移転
                                </p>
                                <p className="p-outline-box-cnt--text">
                                サイバーエリアリサーチ株式会社と業務提携を締結
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2010年<span className="is-date">8月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                行動履歴マーケティングシステム(アトムス)を特許出願
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2010年<span className="is-date">12月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                会社名を株式会社アクロスソリューションズに変更
                                </p>
                                <p className="p-outline-box-cnt--text">
                                資本金を5,000,000円に増資
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2011年<span className="is-date">2月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                丸紅情報システムズ各種ソリューションの取扱いを開始
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2011年<span className="is-date">4月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                モバイル動画サイト自動生成システム「ここみちゃん」をリリース
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2011年<span className="is-date">9月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                ウェブマーケティングのプロ国内100社に選定される
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2011年<span className="is-date">10月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                本社を金沢市北安江3丁目6番6号に移転
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2011年<span className="is-date">11月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                中小規模企業向けモバイル受発注システムMOSベータ版をリリース
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2012年<span className="is-date">3月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                株式会社システムサポートのグループ会社となり流通業向けソリューション強化を図る
                                </p>
                                <p className="p-outline-box-cnt--text">
                                資本金を10,000,000円に増資
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2012年<span className="is-date">7月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                東京事務所を東京都新宿区西新宿6丁目12-1に開設
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2012年<span className="is-date">9月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                SMBCファイナンスサービスと業務提携を締結
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2013年<span className="is-date">1月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                中小規模企業向けモバイル受発注システムMOSをリリース
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2013年<span className="is-date">3月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                リテールテックJAPAN2013に出展(東京ビッグサイト)
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2013年<span className="is-date">9月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                大阪事務所を大阪府大阪市淀川区宮原3-5-24に開設
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2014年<span className="is-date">3月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                東京事務所を東京都渋谷区幡ヶ谷1-34-14宝ビル8Fに移転
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2015年<span className="is-date">7月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                小清水良次（株式会社システムサポート代表取締役社長）が代表取締役会長に就任
                                </p>
                                <p className="p-outline-box-cnt--text">
                                高井健司（株式会社システムサポート）が監査役に就任
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2016年<span className="is-date">3月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                SaaS型クラウド発注サービスMOXをリリース
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2016年<span className="is-date">10月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                中小規模企業向けモバイル受発注システムMOS4をリリース
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2017年<span className="is-date">4月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                    <ExtLink link="https://www.itc.or.jp/datarenkei/index.html" title="MOSが中小企業庁　平成28年度「次世代企業間データ連携調査事業」に採択される" target="true" classTag="c-text--link">
                                    MOSが中小企業庁　平成28年度「次世代企業間データ連携調査事業」に採択される
                                    </ExtLink>
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2018年<span className="is-date">1月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                小規模企業向けASP型受発注サービスMOS Liteをリリース
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2018年<span className="is-date">4月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                    <ExtLink link="https://www.smb-cloud.org/index.php/2020/04/01/moslite-2/" title="MOS Liteが「認定クラウドサービス（一般社団法人クラウドサービス推進機構）」に認定される" target="true" classTag="c-text--link">
                                    MOS Liteが「認定クラウドサービス（一般社団法人クラウドサービス推進機構）」に認定される
                                    </ExtLink>
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2018年<span className="is-date">8月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                システムサポートグループ連結としてマザーズに上場 
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2019年<span className="is-date">2月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                中小規模企業向けモバイル受発注システムMOS5をリリース
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2019年<span className="is-date">8月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                システムサポートグループ連結として東証一部に上場 
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2022年<span className="is-date">4月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                株式会社ブロックの全ての株式を取得し同社を完全子会社化
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2022年<span className="is-date">7月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                株式会社ブロックを吸収合併
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2023年<span className="is-date">7月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                前浜　隆（株式会社システムサポート）が監査役に就任
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2024年<span className="is-date">1月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                旧DM事業部を株式会社STSデジタルとして分社化。
                                </p>
                            </div>
                        </div>

                        <div className="p-outline-box">
                            <div className="p-outline-box-title">
                                <p className="p-outline-box-title--text">
                                2024年<span className="is-date">2月</span>
                                </p>
                            </div>

                            <div className="p-outline-box-cnt">
                                <p className="p-outline-box-cnt--text">
                                能登 満が代表取締役社長に就任
                                </p>

                                <p className="p-outline-box-cnt--text">
                                野村 充史が取締役に就任
                                </p>

                                <p className="p-outline-box-cnt--text">
                                資本金を20,000,000円に増資
                                </p>
                            </div>
                        </div>

                    </div>

                </div>

            </Contents>

        </>
    )
}

export default History;


